import { useState, useEffect } from 'react';
import './App.css';
import Axios from 'axios';

function App() {
  const [data, setData] = useState('--');
  const [value, setValue] = useState('');

  const axios = Axios.create({
    // baseURL: 'http://127.0.0.1:8787/api',
    baseURL: 'https://server.mobuiin.workers.dev/api',
    timeout: 10000,
    headers: { 'Content-Type': 'application/json, text/javascript, */*' }
  });

  useEffect(() => {
    axios.get('/kv-get-data').then(resp => {
      setData(resp.data.data);
    });
  }, []);

  return (
    <div className="App">
      <div className="mt-20">
        <p>获取数据为: {data}</p>
        <div>
          更改数据：
          <input
            value={value}
            onChange={e => {
              setValue(e.target.value);
            }}
          />
          <button
            className="ml-10"
            onClick={async () => {
              const { data } = await axios.get('/kv-set-data', {
                params: {
                  value
                }
              });
              setData(data.data);
              setValue('');
            }}
          >
            设置
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
